.header-text {
    font-weight: 600;
  }
  
  .second-row {
    border-left: 2px solid #eceaea;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  
  @media (max-width: 800px) {
    .second-row {
      border: none !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .first-row {
      display: none !important;
    }
  }
  